/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';

import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PageWrapper from 'Layouts/PageWrapper';
import SEO from 'Components/SEO';

const ResearchPage = ({ data }) => {
  const { researchData: { edges: [ { node: research } ] } } = data;

  return (
    <PageWrapper>
      <SEO
        title={ research.frontmatter.title }
        description={ research.frontmatter.intro }
      />
      <div className="page__hero">
        <h3 className="page__hero__title">{ research.frontmatter.title }</h3>
        <h1 className="page__hero__title">{ research.frontmatter.intro }</h1>

        { !!research?.frontmatter?.intro_img?.childImageSharp && (
          <Img fluid={ research.frontmatter.intro_img.childImageSharp.fluid } />
        ) }
      </div>
      <main className="page__main page__content about__content">
        <div className="about__content__inner" dangerouslySetInnerHTML={ { __html: research.html } } />
        <div id="funding" />
        <div className="about-funding__wrapper">
          <h2 className="page__title">Funding</h2>
          <ul className="about-funding__list">
            { research.frontmatter.funding.map((item, idx) => (
              <li className="about-funding__item" item={ item.title } key={ `${idx * idx}` }>
                { !!item?.image?.childImageSharp?.fluid && (
                  <a className="about-funding__item__link" href={ item.linkURL } target="_blank" rel="noreferrer">
                    <img src={ item.image.childImageSharp.fluid.src } alt={ item.title } className="about-funding__item__logo" />
                  </a>
                ) }
                <a className="about-funding__item__title" href={ item.linkURL } target="_blank" rel="noreferrer">
                  { item.title }
                </a>
              </li>
            )) }
          </ul>
        </div>
      </main>
    </PageWrapper>
  );
};

export default ResearchPage;

export const query = graphql`
  query {
    researchData: allMarkdownRemark(filter: {
      frontmatter: {
        templateKey: {eq: "research"},
      },
    }) {
      totalCount
      edges {
        node {
          html
          frontmatter {
            title
            pre_intro
            intro
            intro_img {
              childImageSharp {
                fluid(maxWidth: 730) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            funding {
              title
              image {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              linkType
              linkURL
            }
          }
        }
      }
    }
  }
`;
